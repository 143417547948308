import BuildInfo from 'components/BuildInfo';
import SearchFestival from 'components/search/SearchFestival';

const FestivalPage = () => {
  return (
    <div>
      <SearchFestival />
      <BuildInfo />
    </div>
  );
};

export default FestivalPage;
