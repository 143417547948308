import classNames from 'classnames';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useCallback, useEffect, useMemo, useState} from 'react';
import useDimmedQuery from 'hooks/useDimmedQuery';

import actions from 'ducks/actions';
import {
  EAdditionalInfo,
  ECategoryType,
  TAroundMeSelectableParams,
  categoryTypeList,
} from 'ducks/tplacehome/types';
import {IcOption} from 'components/@tmds/icons/v1.2/IcOption';
import ParamSelectPopup from './popups/ParamSelectPopup';
import FilterSelectPopup from './popups/FilterSelectPopup';
import {IcCheck} from 'components/@tmds/icons/v1.2/IcCheck';
import {getDistanceBetweenCoords} from 'utils/map';
import {fetchAroundMeList} from 'ducks/tplacehome/slice';
import TopInfoBox from '../shared/TopInfoBox';
import {ReactComponent as IconDivider} from 'resource/images/divider.svg';

import s from 'styles/components/tplacehome/TPlaceAroundMeHeader.module.scss';
import TmapAiIcon from '../shared/TmapAiIcon';
import usePlaceHome from 'hooks/usePlaceHome';
import {isEmpty} from 'utils/lodash';

const clickLogActionIdMap = {
  category: {
    [ECategoryType.FOOD_ALL]: 'tap.filter_food',
    [ECategoryType.FOOD_CAFE]: 'tap.filter_cafe',
    [ECategoryType.FOOD_BAR]: 'tap.filter_alcohol',
  },
  additional: {
    [EAdditionalInfo.OPENED]: 'tap.filter_open',
    [EAdditionalInfo.PARKING]: 'tap.filter_parking',
    [EAdditionalInfo.RESERVATION]: 'tap.filter_reserve_waiting',
  },
};

const AroundMeHeader = () => {
  const {tplacehome, userInfo, layout} = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const placehomeHook = usePlaceHome();

  const paramSelectPopupQuery = useDimmedQuery({id: 'PARAM_SELECT_POPUP'});
  const filterSelectPopupQuery = useDimmedQuery({id: 'FILTER_SELECT_POPUP'});

  const [computedRegionName, setComputedRegionName] = useState('');

  /**
   * 상단 "점심, 내 주변 1km" 텍스트
   */
  const paramText = useMemo(() => {
    const requestParams = tplacehome.aroundMeData.result.data.requestParams;
    if (!requestParams) {
      return '';
    }

    let timeText = '';
    switch (requestParams.timeOfDay) {
      case 'morning':
        timeText = '아침/오전';
        break;
      case 'lunch':
        timeText = '점심';
        break;
      case 'afternoon':
        timeText = '오후';
        break;
      case 'dinner':
        timeText = '저녁';
        break;
    }
    const radiusText =
      requestParams.radius < 1000
        ? `${requestParams.radius}m`
        : `${(requestParams.radius * 0.001).toFixed()}km`;
    return `${timeText}, ${computedRegionName} 주변 ${radiusText}`;
  }, [computedRegionName, tplacehome.aroundMeData.result.data.requestParams]);

  const hasFilter = useMemo(() => {
    const groups = tplacehome.aroundMeData.selectedFilters.categoryGroups;
    const infos = tplacehome.aroundMeData.selectedFilters.additionalInfos;
    return !(isEmpty(groups) && isEmpty(infos));
  }, [
    tplacehome.aroundMeData.selectedFilters.additionalInfos,
    tplacehome.aroundMeData.selectedFilters.categoryGroups,
  ]);

  /**
   * 상단 "점심, 내 주변 1km" 텍스트 에서 "내 주변" 부분 갱신
   */
  useEffect(() => {
    if (tplacehome.aroundMeData.result.loaded) {
      const {home, office} = userInfo.personalPlace.data;
      const requestParams = tplacehome.aroundMeData.result.data.requestParams;
      if (!requestParams) {
        return;
      }
      const destPosition = {lat: requestParams.destLat, lon: requestParams.destLon};
      const userPosition = {lat: requestParams.userRealLat, lon: requestParams.userRealLon};
      const destDistanceKM = getDistanceBetweenCoords(destPosition, userPosition);
      const isAroundUser = destDistanceKM < 0.2;
      if (isAroundUser) {
        const aroundDistance = 0.5;
        const homePosition = home && {lon: home.wgs84NavX, lat: home.wgs84NavY};
        const officePosition = office && {lon: office.wgs84NavX, lat: office.wgs84NavY};
        const isAroundHome =
          homePosition && getDistanceBetweenCoords(userPosition, homePosition) < aroundDistance;
        const isAroundOffice =
          officePosition && getDistanceBetweenCoords(userPosition, officePosition) < aroundDistance;
        if (isAroundHome) {
          setComputedRegionName('집');
        } else if (isAroundOffice) {
          setComputedRegionName('회사');
        } else {
          setComputedRegionName('내');
        }
      } else {
        setComputedRegionName(tplacehome.aroundMeData.result.data.requestGeoInfo.regionName3);
      }
    }
  }, [
    tplacehome.aroundMeData.result.data.requestGeoInfo.regionName3,
    tplacehome.aroundMeData.result.data.requestParams,
    tplacehome.aroundMeData.result.loaded,
    userInfo.personalPlace.data,
  ]);

  /**
   * popup handlers
   */
  const handleParamSelector = useCallback(() => {
    placehomeHook.sendEventAroundMe('tap.filter_around_ranking');
    paramSelectPopupQuery.open();
  }, [paramSelectPopupQuery, placehomeHook]);

  const handleSubmitParams = useCallback(
    (params: TAroundMeSelectableParams) => {
      paramSelectPopupQuery.close();
      dispatch(actions.tplacehome.setAroundMeSelectedParams(params));
      dispatch(fetchAroundMeList({}));
    },
    [dispatch, paramSelectPopupQuery]
  );

  const handleFilterSelector = useCallback(() => {
    placehomeHook.sendEventAroundMe('tap.filter_all');
    filterSelectPopupQuery.open();
  }, [filterSelectPopupQuery, placehomeHook]);

  const handleSubmitFilters = useCallback(
    (value) => {
      filterSelectPopupQuery.close();
      dispatch(actions.tplacehome.setAroundMeSelectedFilters(value));
    },
    [dispatch, filterSelectPopupQuery]
  );

  /**
   * render
   */
  return (
    <>
      <TopInfoBox>티맵 이동 데이터로 만든 내 주변 인기 장소</TopInfoBox>
      <div className={s.params_wrap}>
        <div
          className={s.state_text_button}
          onClick={() => handleParamSelector()}
          style={{visibility: computedRegionName ? 'visible' : 'hidden'}}
        >
          <span>{paramText}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.52851 11.1377L2.26184 5.87108L3.20465 4.92827L7.99991 9.72353L12.7952 4.92827L13.738 5.87108L8.47132 11.1377C8.21097 11.3981 7.78886 11.3981 7.52851 11.1377Z"
              fill="#171819"
            />
          </svg>
        </div>
        {layout.windowSize.width >= 320 && <TmapAiIcon />}
      </div>
      <div className={s.filters_wrap} data-flicking-ignore>
        <div className={s.button_wrap}>
          <div className={s.button_group}>
            <button
              className={classNames(s.button_all, {[s.filter_checked]: hasFilter})}
              onClick={() => handleFilterSelector()}
            >
              <IcOption width={20} height={20} />
            </button>
          </div>
          <div className={s.button_group}>
            {placehomeHook.computedAdditionalFilterList.map((item) => (
              <button
                key={item.value}
                className={classNames(s.button_filter, {
                  [s.filter_checked]:
                    tplacehome.aroundMeData.selectedFilters.additionalInfos.includes(item.value),
                })}
                onClick={() => {
                  placehomeHook.sendEventAroundMe(clickLogActionIdMap.additional[item.value]);
                  dispatch(actions.tplacehome.toggleAdditionalInfo(item.value));
                }}
              >
                {item.name}
              </button>
            ))}
          </div>
          {/*<IcoLineBetween color="gray200" width={17} height={22} />*/}

          <div className={s.divider}>
            <IconDivider />
          </div>
          <div className={s.button_group}>
            {categoryTypeList.map((item) => (
              <button
                key={item.value}
                className={classNames(s.button_filter, {
                  [s.filter_checked]:
                    tplacehome.aroundMeData.selectedFilters.categoryGroups.includes(item.value),
                })}
                onClick={() => {
                  placehomeHook.sendEventAroundMe(clickLogActionIdMap.category[item.value]);
                  dispatch(actions.tplacehome.toggleCategoryGroup(item.value));
                }}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className={s.distance_wrap}>
        <button
          className={s.button}
          onClick={() => {
            placehomeHook.sendEventAroundMe('tap.sorting_distance');
            dispatch(actions.tplacehome.toggleAroundMeSortByDistance());
          }}
        >
          <IcCheck
            width={18}
            height={18}
            color={tplacehome.aroundMeData.sortByDistance ? 'tmobiBlue500' : 'gray500'}
          />
          <span
            className={classNames(s.text, {[s.text_on]: tplacehome.aroundMeData.sortByDistance})}
          >
            거리순
          </span>
        </button>
      </div>
      <ParamSelectPopup onClickSubmit={handleSubmitParams} isOpen={paramSelectPopupQuery.isOpen} />
      <FilterSelectPopup
        onClickSubmit={handleSubmitFilters}
        isOpen={filterSelectPopupQuery.isOpen}
      />
    </>
  );
};

export default AroundMeHeader;
