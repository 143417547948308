import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import TMapSender from '@lcc/tmap-inapp';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {VSMInterfaceProvider} from 'context/VSMInterfaceContext';
import {Paths} from 'constant/RoutePath';
import {StorageKey} from 'constant/Storage';
import {EActionId} from 'constant/Log';
import ua from 'utils/uaParser';
import * as TMapInApp from 'utils/tmapInApp';
import {TListDrawerResult} from 'types/ListDrawer';
import {getAllParams} from 'utils/apis';
import {generateUrl, moveToUrl} from 'utils/url';
import useUserData from 'hooks/useUserData';
import useLogger from 'hooks/useLogger';
import useMapOffset from 'hooks/useMapOffset';
import {filterQueryToCategoryParam} from 'hooks/useValidCategory';
import {useOnce} from 'hooks/useOnce';
import {useRemoteConfig} from 'hooks/useRemoteConfig';
import {getValidLonLat} from 'utils/map';
import {HistoryBackLink} from 'components/HistoryBackLink';
import {CENTER_WGS84} from 'constant/Map';
import PlaceVerticalPopup from 'components/place/PlaceVerticalPopup';
import {PLACE_CONFIG} from 'constant/Firebase';
import PlaceTagList from 'components/PlaceTagList';
import DrawerContainer from 'components/DrawerContainer';
import PlaceMap from 'components/PlaceMap';
import VSMCompass from 'components/VSMCompass';
import BuildInfo from 'components/BuildInfo';
import {
  TNOW_CATEGORY_AREA_HEIGHT,
  TNOW_CENTER_POINT_MIN_HEIGHT,
  TNOW_LIST_HANDLE_HEIGHT,
  TNOW_CENTER_MAP_RATIO,
  DEFAULT_HANDLE_BORDER_RADIUS,
  DEFAULT_HANDLE_SIZE,
  MAX_MARKER_WIDTH,
  MAX_MARKER_HEIGHT,
  MAX_MARKER_TITLE_HEIGHT,
} from 'constant/Size';
import {ReactComponent as IconArrow} from 'resource/images/@tmds_element/ico_arrow_left.svg';

import {ETPlaceTab} from 'ducks/tplacehome/types';
import TPlaceTab from 'components/tplacehome/TPlaceTab';
import TPlaceMain from 'components/tplacehome/TPlaceMain';

import s from 'styles/pages/PlaceMainPage.module.scss';
import {fetchAroundMeList, fetchRegionListDepth1} from 'ducks/tplacehome/slice';
import classNames from 'classnames';
import {TPlaceItem} from 'ducks/place/types';
import {isEmpty} from 'utils/lodash';
import {EAppRequestMode} from 'types/App';
import usePlaceHome from 'hooks/usePlaceHome';
import {useParamLog} from 'hooks/useParamLog';
import {getCurrentPackageVersion} from 'utils/tmapUtils';

const PlaceHomePage = () => {
  const dispatch = useAppDispatch();
  const {sendClickLogWithMapView} = useLogger();
  const {map, userInfo, tplacehome, layout, userInteraction} = useAppSelector((state) => state);

  const history = useHistory();
  const params = useMemo(() => getAllParams(), []);
  const placehomeHook = usePlaceHome();

  const {centerOffset, getBoundsPaddingAndOffset} = useMapOffset({
    offsetTop: TNOW_CATEGORY_AREA_HEIGHT,
  });
  const isStandAloneView = useMemo(() => params.tailParam.stand_alone_view === 1, [params]);

  useUserData({ignorePosition: true});
  useRemoteConfig(PLACE_CONFIG);

  // preprocess
  useOnce(userInfo.userKey && userInfo.sessionId, async () => {
    // redirect - 서브페이지, category 경로로 들어온 경우 redirect
    const deepLinkTarget = filterQueryToCategoryParam(params);
    if (deepLinkTarget?.type) {
      sendClickLogWithMapView(EActionId.DEEPLINK_OPEN, {
        category: params.category,
        reqKey: params.reqKey,
      });

      const targetPath = generateUrl(Paths.PlaceCategory, {
        ...deepLinkTarget,
        tailParam: JSON.stringify(params?.tailParam || {}),
      });

      history.replace(Paths.PlaceMain);
      moveToUrl(window.location.origin + targetPath);

      // aos 에서 deeplink가 올때마다 티지금 탭에 새로운 페이지로 history를 쌓고 있어서 history.back으로 돌아가줌
      // aos 가 기본쪽으로 0페이지에 파라미터 없는 '/app/place/main' 을 가지고 있음
      // ios 의 경우 백버튼이 없기에 상관 없지만 정상 동작하는것 확인 됨
      // replace는 혹시나 0번째 화면이라 back 이 없을때 현재 페이지를 초기화해서 새로고치도록 적용
      if (ua.isInApp && ua.isAndroid) {
        window.history.back();
        return;
      }
    }

    // preload data. 페이지 실행전에 필요한 데이터 로드
    const paramLonLat = getValidLonLat({lon: params.centerLon, lat: params.centerLat});
    const mapPitch = params.tailParam.mapPitch;
    const mapBearing = params.tailParam.mapBearing;

    mapPitch && dispatch(actions.map.setPitch(mapPitch));
    mapBearing && dispatch(actions.map.setPitch(mapBearing));

    try {
      const position = await TMapInApp.getLastPosition();
      const mapCenter = paramLonLat || position;
      dispatch(actions.map.setUserPosition(position));
      dispatch(actions.map.setNowCenter(mapCenter));
    } catch {
      const mapCenter = paramLonLat || CENTER_WGS84;
      dispatch(actions.map.setUserPosition(CENTER_WGS84));
      dispatch(actions.map.setNowCenter(mapCenter));
    }

    // 1뎁스 리스트 (다른지역 리스트 노출을 위함. 비동기여도 무관.)
    dispatch(fetchRegionListDepth1({}));

    dispatch(actions.userInteraction.setDrawerRefreshTailText('에서 재탐색'));
    dispatch(actions.tplacehome.setInitialDataLoaded());
  });

  const drawerCenterPoint = useMemo(
    () =>
      Math.max(
        parseInt(`${layout.appSize.displayHeight * TNOW_CENTER_MAP_RATIO}`, 10),
        TNOW_CENTER_POINT_MIN_HEIGHT
      ),
    [layout.appSize.displayHeight]
  );

  const handleRefresh = useCallback(
    (position) => {
      dispatch(fetchAroundMeList({destLat: position.lat, destLon: position.lon}));
    },
    [dispatch]
  );

  // 맵용 list. flicking과 동시에 변경되면 버벅임. 약간의 delay를 주기 위함
  const mapListInitalFlagRef = useRef(false);
  const [mapList, setMapList] = useState<TPlaceItem[]>([]);
  useEffect(() => {
    const delay = 500;
    if (tplacehome.currentTab === ETPlaceTab.AROUND_ME) {
      const newList = tplacehome.aroundMeData.result.data.filteredList;
      if (isEmpty(newList) && !mapListInitalFlagRef.current) {
        return;
      }
      if (mapListInitalFlagRef.current) {
        setTimeout(() => setMapList(tplacehome.aroundMeData.result.data.filteredList), delay);
      } else {
        setMapList(tplacehome.aroundMeData.result.data.filteredList);
        mapListInitalFlagRef.current = true;
      }
    } else {
      setTimeout(() => setMapList([]), delay);
    }
  }, [tplacehome.aroundMeData.result.data.filteredList, tplacehome.currentTab]);

  // 드로워가 최상단에 위치했는지 판단 (shadown 표현을 위함)
  const [isTopPosition, setIsTopPosition] = useState(true);
  const showTopShadow = useMemo(
    () => isTopPosition && !layout.appSize.isLandscape && !userInteraction.calloutInfo,
    [isTopPosition, layout.appSize.isLandscape, userInteraction.calloutInfo]
  );

  // 어디갈까를 이탈하고(다른탭 이동) 5분이 지나고 되돌아오면 reset 한다.
  // android에서 location.href로 다른 서비스를 호출할때 onPause가 호출되지 않음. blur 이벤트로 보완
  const bodyBlurCallback = useCallback(
    () => dispatch(actions.userInteraction.setPauseKey(Date.now())),
    [dispatch]
  );
  useEffect(() => {
    window.addEventListener('blur', bodyBlurCallback);
    return () => window.removeEventListener('blur', bodyBlurCallback);
  }, [bodyBlurCallback]);
  // 초기 세팅
  useEffect(() => {
    const now = Date.now();
    dispatch(actions.userInteraction.setPauseKey(now));
    dispatch(actions.userInteraction.setResumeKey(now));
  }, [dispatch]);
  useEffect(() => {
    const resumeKey = userInteraction.resumeKey || 0;
    const pauseKey = userInteraction.pauseKey || 0;
    const resetDelay = 1000 * 60 * 5; // 5분
    if (resumeKey > pauseKey + resetDelay) {
      window.location.reload();
    }
  }, [dispatch, userInteraction.pauseKey, userInteraction.resumeKey]);

  /**
   * android에서 resume시 repaint하기 위함
   * 어디갈까 > 검색 > 장소상세 > 장소상세+1 > 길안내 > 안내중 종료시 간헐적 백화현상으로 강제로 repaint 하기 위함.
   * https://tmobi.atlassian.net/browse/LOCALQA-568
   */
  useEffect(() => {
    const bodyEl = document.querySelector('body') as HTMLBodyElement;
    bodyEl.style.transform = 'translate3d(0, 0, 0)';
    setTimeout(() => (bodyEl.style.transform = ''), 50);
  }, [userInteraction.resumeKey]);

  /**
   * log 관련
   */
  useOnce(!!userInfo.accessKey, () => placehomeHook.logInit());
  // tab expose
  const {referrer} = useParamLog();
  useEffect(() => {
    placehomeHook.updatePageId();
    const custom = {
      refferal: referrer, // 확인필요
      mapview: placehomeHook.getDrawerModeIndex(tplacehome.currentDrawerListMode),
      package_version: getCurrentPackageVersion(),
    };
    placehomeHook.sendExpose(custom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tplacehome.currentTab]);
  // 드로어 업다운
  const beforeDrawerMode = useRef(tplacehome.currentDrawerListMode);
  useEffect(() => {
    if (beforeDrawerMode.current !== tplacehome.currentDrawerListMode) {
      const beforeIndex = placehomeHook.getDrawerModeIndex(beforeDrawerMode.current);
      const currentIndex = placehomeHook.getDrawerModeIndex(tplacehome.currentDrawerListMode);
      const actionId = beforeIndex < currentIndex ? 'drag_up.drawer' : 'drag_down.drawer';
      placehomeHook.sendEvent(actionId, {mapview: currentIndex});
      beforeDrawerMode.current = tplacehome.currentDrawerListMode;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tplacehome.currentDrawerListMode]);

  /**
   * render
   */
  return (
    <VSMInterfaceProvider>
      <div className={s.header} style={{top: layout.appSize.statusBarHeight}}>
        {isStandAloneView ? (
          <HistoryBackLink className={s.back}>
            <IconArrow />
          </HistoryBackLink>
        ) : (
          <>
            <button
              className={s.search_button}
              onClick={() => {
                placehomeHook.sendEvent('tap.search_field');
                TMapSender.showSearch({
                  keyword: '',
                  extra: JSON.stringify({reqMode: EAppRequestMode.TPLACE_HOME}),
                });
              }}
            >
              흑백요리사, 맛집, 카페, 음식 검색
            </button>
            <PlaceTagList />
          </>
        )}
        <div className={classNames(s.top_shadow, {[s.show]: showTopShadow})} />
        {tplacehome.initialDataLoaded && <VSMCompass className={s.vsm_compass} />}
      </div>

      <DrawerContainer
        list={[]}
        listMode={tplacehome.currentDrawerListMode}
        listTopAreaPadding={70}
        tooltipStorageKey={StorageKey.TNOW_TOOLTIP}
        onChangeListMode={(drawerMode) => {
          dispatch(actions.tplacehome.setCurrentDrawerListMode(drawerMode));
        }}
        mapComponent={(drawerProps: TListDrawerResult) => {
          const {boundOffset, boundsPadding} = getBoundsPaddingAndOffset(drawerProps, {
            headerHeight: 130,
            maxMarkerHeight: 0,
            maxMarkerWidth: MAX_MARKER_WIDTH,
            maxMarkerTitleHeight: MAX_MARKER_TITLE_HEIGHT + (ua.isAndroid ? 20 : 0),
          });

          return tplacehome.initialDataLoaded && map.userPosition && boundsPadding ? (
            <PlaceMap
              initPitch={map.nowPitch}
              initBearing={map.nowBearing}
              priorityBounds={{top: MAX_MARKER_HEIGHT}}
              boundsPadding={boundsPadding}
              list={mapList}
              markerClickToCenter={true}
              centerOffset={centerOffset}
              boundOffset={boundOffset}
              useUserPositionBound={true}
            />
          ) : (
            <></>
          );
        }}
        drawerOptions={{
          centerHeight: drawerCenterPoint,
          topAreaHeight: TNOW_CATEGORY_AREA_HEIGHT,
          listHandleHeight: TNOW_LIST_HANDLE_HEIGHT,
        }}
        statusBarHeight={layout.appSize.statusBarHeight}
        handleBorderRadius={layout.appSize.isLandscape ? 0 : DEFAULT_HANDLE_BORDER_RADIUS}
        handleSize={
          layout.appSize.isLandscape
            ? layout.appSize.statusBarHeight + DEFAULT_HANDLE_SIZE
            : DEFAULT_HANDLE_SIZE
        }
        listHeaderComponent={<TPlaceTab />}
        refreshButtonVisible={tplacehome.currentTab === ETPlaceTab.AROUND_ME}
        onRefresh={handleRefresh}
        listComponent={null}
        fixedContentMode={true}
        fixedContent={<TPlaceMain key={tplacehome.refreshKey} />}
        onChangeBottomPadding={(value) => {
          dispatch(actions.tplacehome.setContentBottomPadding(value));
        }}
        onChangePositionMode={(values) => {
          setIsTopPosition(values.isTop);
        }}
        isHideToTop={true}
      />
      <BuildInfo />
      {tplacehome.initialDataLoaded && <PlaceVerticalPopup />}
    </VSMInterfaceProvider>
  );
};

export default PlaceHomePage;
